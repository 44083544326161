import {useEffect, useState} from 'react';

import {useUser} from '@auth0/nextjs-auth0';
import {FeatureFlagsContext} from '@contexts/feature-flags';

import flagsmith from 'flagsmith';

import {LoadingView} from '@tetra-next/nsui';

import {FeatureFlagsProps} from '@interfaces/featureFlags.types';

const initiateFeatureFlags = async (environmentID: string) => {
  await flagsmith.init({
    environmentID: environmentID,
  });
};

export const FeatureFlagsProvider = ({
  children,
  environmentID,
}: FeatureFlagsProps) => {
  const {isLoading, user} = useUser();
  const [featFlagsInitiated, setFeatFlagsInitiated] = useState<boolean>();
  useEffect(() => {
    if (user) {
      (async () => {
        if (!featFlagsInitiated) {
          await flagsmith.identify(user?.email ?? '');
          await initiateFeatureFlags(environmentID);
          await flagsmith.getFlags();
          setFeatFlagsInitiated(true);
        }
      })();
    }
  }, [user]);

  return (
    <FeatureFlagsContext.Provider
      value={{
        userId: user?.email ?? '',
        environmentID: environmentID,
      }}
    >
      {isLoading || (user && !featFlagsInitiated) ? (
        <LoadingView />
      ) : (
        <>{children}</>
      )}
    </FeatureFlagsContext.Provider>
  );
};
