import {UserProvider} from '@auth0/nextjs-auth0';
import {CacheProvider} from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import {NextIntlProvider} from 'next-intl';
import Head from 'next/head';
import Script from 'next/script';
import {SWRConfig} from 'swr';

import {ChannelsProvider} from '@components/providers/channels';
import {FeatureFlagsProvider} from '@components/providers/featureFlags';
import createEmotionCache from '@styles/createEmotionCache';
import '@styles/globals.css';
import {muiTheme} from '@tetra-next/nsui';
import renderSegmentSnippet from '@tetra-next/platform-analytics';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const AuthSessionProvider = (props) => {
  const {children} = props;

  return <UserProvider>{children}</UserProvider>;
};

// Use the <Provider> to improve performance and allow components that call
export default function App(props) {
  const {Component, emotionCache = clientSideEmotionCache, pageProps} = props;

  return (
    <CacheProvider value={emotionCache}>
      <AuthSessionProvider>
        <FeatureFlagsProvider environmentID={process.env.FLAGSMITH_ENV_ID}>
          <ChannelsProvider>
            <NextIntlProvider
              // To achieve consistent date, time and number formatting
              // across the app, you can define a set of global formats.
              formats={{
                dateTime: {
                  short: {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  },
                },
              }}
              messages={pageProps.messages}
              // Providing an explicit value for `now` ensures consistent formatting of
              // relative values regardless of the server or client environment.
              now={pageProps.now}
              // Also an explicit time zone is helpful to ensure dates render the
              // same way on the client as on the server, which might be located
              // in a different time zone.
              timeZone="Universal"
            >
              <Head>
                <title>Tetra Next</title>
                <meta
                  content="initial-scale=1, width=device-width"
                  name="viewport"
                />
                <link
                  href="/icons/apple-touch-icon.png"
                  rel="apple-touch-icon"
                  sizes="180x180"
                />
                <link
                  href="/icons/favicon-32x32.png"
                  rel="icon"
                  sizes="32x32"
                  type="image/png"
                />
                <link
                  href="/icons/favicon-16x16.png"
                  rel="icon"
                  sizes="16x16"
                  type="image/png"
                />
                <link href="/icons/site.webmanifest" rel="manifest" />
                <link
                  color="#5bbad5"
                  href="/icons/safari-pinned-tab.svg"
                  rel="mask-icon"
                />
                <link href="/icons/favicon.ico" rel="shortcut icon" />
                <meta content="#f1f2f6" name="msapplication-TileColor" />
                <meta
                  content="/icons/browserconfig.xml"
                  name="msapplication-config"
                />
                <meta content="#ffffff" name="theme-color" />
              </Head>
              <Script
                // Using Vercel's suggested approach:
                // https://github.com/vercel/next.js/blob/canary/examples/with-segment-analytics/pages/_app.js
                dangerouslySetInnerHTML={{__html: renderSegmentSnippet()}}
                id="segment-script"
              />
              <SWRConfig value={{refreshInterval: 30_000}}>
                <ThemeProvider theme={muiTheme}>
                  <CssBaseline />
                  <Component {...pageProps} />
                </ThemeProvider>
              </SWRConfig>
            </NextIntlProvider>
          </ChannelsProvider>
        </FeatureFlagsProvider>
      </AuthSessionProvider>
    </CacheProvider>
  );
}
