import {useUser} from '@auth0/nextjs-auth0';
import {ChannelsContext} from '@src/contexts/channels';
import Pusher from 'pusher-js';

export const ChannelsProvider = ({children}) => {
  const {user} = useUser();
  const pusher = new Pusher(process.env.PUSHER_KEY, {
    cluster: process.env.PUSHER_CLUSTER,
    forceTLS: true,
    channelAuthorization: {
      endpoint: `${process.env.CMS_BASE_URL}/pusher/auth`,
      transport: 'ajax',
      headers: {
        Authorization: `Bearer ${process.env.CMS_API_TOKEN}`,
      },
      params: {
        user_id: user?.email,
      },
    },
    userAuthentication: {
      endpoint: `${process.env.CMS_BASE_URL}/pusher/user-auth`,
      transport: 'ajax',
      params: {
        id: user?.email,
        firstname: user?.nickname,
        lastname: user?.name,
        email: user?.email,
      },
      headers: {
        Authorization: `Bearer ${process.env.CMS_API_TOKEN}`,
      },
    },
  });
  pusher.bind('pusher:signin_success', () => {
    console.log('pusher:signin_success');
  });
  pusher.bind('pusher:error', (data) => {
    return console.error('pusher:error', data);
  });

  return (
    <ChannelsContext.Provider
      value={{
        pusher: pusher,
      }}
    >
      {children}
    </ChannelsContext.Provider>
  );
};
