import {createContext} from 'react';

export type FeatureFlagsContextData = {
  environmentID: string;
  userId: string;
};

export const FeatureFlagsContext = createContext<FeatureFlagsContextData>({
  environmentID: '',
  userId: '',
});
