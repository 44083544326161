import * as snippet from '@segment/snippet';

const renderSnippet = () => {
  const opts = {
    apiKey: process.env.SEGMENT_WRITEKEY,
    page: true,
  };

  if (process.env.ANALYTICS_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
};
``;

/* Segments Tracking Methods Section
 * ******************************************* */
/**
 * Use the segmentIdentify method to link your users and their actions, to a recognizable userId and traits.
 * @param args {...object} [userId], [traits], [options], [callback]
 *
 * * **[userId]**  **String**:  The database ID for the user.
 * * **[traits]**  **Object**:  A dictionary of traits you know about the user, like email or name.
 * * **[options]**  **Object**:  A dictionary of options.
 * * **[callback]**  **Function**:  A function executed after a short timeout, giving the browser time to make outbound requests first.
 *
 * @example
 * segmentIdentify('rlarin', {
 *    name: 'Rainer Larin',
 *    email: 'rainer@tetra.team'
 *  }, callbackHandler);
 *
 * @see {@link https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify|Segment Docs} for further information.
 */
export const segmentIdentify = (...args) => {
  global?.analytics.identify(...args);
};

/**
 * The segmentTrack method lets you record actions your users perform.
 * @param  event {string} The name of the event you’re tracking.
 * @param  args {...object} [properties], [options], [callback]
 *
 * * **[properties]**  **Object**:  A dictionary of properties for the event.
 * * **[options]**  **Object**:  A dictionary of options.
 * * **[callback]**  **Function**:  A function that runs after a short timeout, giving the browser time to make outbound requests first.
 *
 * @example
 *  segmentTrack('recordingStarted', {
 *    startTime: Date.now()
 *  }, {
 *    integrations: {
 *      'Zoom': true,
 *      'MSTeams': false
 *    }
 *  }, callbackHandler);
 *
 * @see {@link https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track|Segment Docs} for further information.
 */
export const segmentTrack = (event, ...args) => {
  global?.analytics?.track(event, ...args);
};

/**
 * The segmentTrackLink method is a helper method that attaches the track call as a handler to a link
 * @param element {Element | Element[]}	DOM element or array to bind with track method.
 * @param event {String | Function} The name of the event, passed to the track method. Or a function that returns a string to use as the name of the track event.
 * @param properties {Object | Function}	A dictionary of properties to pass with the track method or a function that returns an object to use as the properties of the event.
 *
 * @example
 *
 * var link = document.getElementById('free-trial-link');
 *
 * segmentTrackLink(link, 'Clicked Free-Trial Link', {
 *    plan: 'Enterprise'
 * });
 *
 * @see {@link https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track-link|Segment Docs} for further information.
 */
export const segmentTrackLink = (element, event, properties) => {
  global?.analytics?.trackLink(element, event, properties);
};

/**
 * The segmentTrackForm method is a helper method that binds a track call to a form submission.
 * @param form {Element | Element[]}	The form element to track or an array of form elements or jQuery objects.
 * @param event {String | Function}	The name of the event, passed to the track method. Or a function that returns a string to use as the name of the track event.
 * @param properties {Object | Function}	A dictionary of properties to pass with the track method. Or a function that returns an object to use as the properties of the event.
 *
 * @example
 * var form = document.getElementById('signup-form');
 *
 * segmentTrackForm(form, 'Signed Up', {
 *    plan: 'Premium',
 *    revenue: 99.00
 * });
 *
 * @see {@link https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track-form|Segment Docs} for further information.
 */
export const segmentTrackForm = (form, event, properties) => {
  global?.analytics?.trackForm(form, event, properties);
};

/**
 * The segmentPage method lets you record page views,
 * along with optional extra information about the page viewed by the user.
 * @param args {...object}  [category], [name], [properties], [options], [callback]
 *
 * * **[category]**  **String**:  The category of the page.
 * * **[name]**  **String**:  The name of the page.
 * * **[properties]**  **Object**:  A dictionary of properties of the page.
 * * **[options]**  **Object**:  A dictionary of properties of the page.
 * * **[callback]**  **Function**:  A function that runs after a short timeout, giving the browser time to make outbound requests first.
 *
 * @example
 * segmentPage('Pricing', {
 *    title: 'Segment Pricing',
 *    url: 'https://tetrainsights.com/pricing',
 *    path: '/pricing',
 *    referrer: 'https://tetrainsights.com/enterprise'
 * });
 *
 * @see {@link https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page|Segment Docs} for further information.
 */
export const segmentPage = (...args) => {
  global?.analytics?.page(...args);
};
/* /Segments Tracking Methods Section
 * ******************************************* */

export default renderSnippet;
